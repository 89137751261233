<template>
  <a-spin :spinning="pageLoading">
    <a-row class="title"> Code Name: {{ codeName }} </a-row>
    <a-row class="btn">
      <a-button :disabled="systemDefined" type="primary" @click="handleAdd()">{{
        $t("page.add")
      }}</a-button>
    </a-row>
    <a-row>
      <a-table
        style="font-size:12px;"
        :columns="columns"
        :pagination="false"
        size="small"
        :rowKey="(record, index) => index"
        :dataSource="dataSource"
        bordered
      >
        <span slot="codeValue" slot-scope="obj">
          <a-input v-model="obj.codeValue"></a-input>
        </span>
        <span slot="isActive" slot-scope="obj">
          <a-radio-group v-model="obj.isActive">
            <a-radio :value="0">active</a-radio>
            <a-radio :value="1">inactive</a-radio>
          </a-radio-group>
        </span>
        <span slot="orderPosition" slot-scope="obj">
          <a-input-number
            :min="0"
            :step="1"
            v-model="obj.orderPosition"
          ></a-input-number>
        </span>
        <span slot="codeDescription" slot-scope="obj">
          <a-input v-model="obj.codeDescription"></a-input>
        </span>
        <span slot="action" slot-scope="obj">
          <span>
            <a @click="handleDelete(obj.id)" :disabled="systemDefined">{{
              $t("page.delete")
            }}</a>
          </span>
        </span>
      </a-table>
    </a-row>
    <a-row class="btn">
      <a-button :disabled="systemDefined" type="primary" @click="Comfire()">{{
        $t("page.comfire")
      }}</a-button>
    </a-row>
  </a-spin>
</template>
<script>
import { apiGetCodeByName, apiCreateCodeValue } from "@/api/code";
export default {
  data() {
    return {
      queryObj: {},
      codeName: "",
      id: "",
      systemDefined: false,
      pageLoading: false,
      columns: [
        {
          // values参数 枚举值
          title: "codeValue",
          scopedSlots: { customRender: "codeValue" },
          align: "center"
        },
        {
          // 0 活跃 1不活跃
          title: "isActive",
          scopedSlots: { customRender: "isActive" },
          align: "center"
        },
        {
          //排序，0,1,2
          title: "orderPosition",
          scopedSlots: { customRender: "orderPosition" },
          align: "center"
        },
        {
          //描述
          title: "codeDescription",
          scopedSlots: { customRender: "codeDescription" },
          align: "center"
        },
        {
          // 操作
          title: this.$t("operate.operate"),
          scopedSlots: { customRender: "action" },
          align: "center"
        }
      ],
      dataSource: []
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.queryObj = to.query;
    this.codeName = to.query.codeName;
    this.table();
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.queryObj = to.query;
    });
  },
  async mounted() {
    this.queryObj = this.$route.query;
    this.codeName = this.$route.query.codeName;
    this.table("init");
  },
  methods: {
    async table(order) {
      this.pageLoading = true;
      let params = { name: this.codeName };
      await apiGetCodeByName(params).then(res => {
        this.id = res.id;
        // this.systemDefined = res.systemDefined;
        let data = [];
        res.values.map((item, idx) => {
          item.isActive = parseInt(item.isActive);
          data.push({
            codeValue: item.codeValue,
            isActive: item.isActive,
            orderPosition: item.orderPosition,
            codeDescription: item.codeDescription,
            id: idx
          });
        });
        this.dataSource = data;
        this.pageLoading = false;
      });
    },
    // 添加
    handleAdd() {
      if (this.dataSource.length > 0) {
        const id = this.dataSource[this.dataSource.length - 1].id + 1;
        this.dataSource = [
          ...this.dataSource,
          {
            codeValue: "",
            isActive: 0,
            orderPosition: 0,
            codeDescription: "",
            id
          }
        ];
      } else {
        this.dataSource = [
          {
            codeValue: "",
            isActive: 0,
            orderPosition: 0,
            codeDescription: "",
            id: 0
          }
        ];
      }
    },
    // 删除
    handleDelete(id) {
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter(item => item.id !== id);
    },
    // 确认修改
    Comfire() {
      let param = {
          codeId: this.id,
          values: []
        },
        count = 0;
      this.dataSource.map(item => {
        if (item.codeValue === "") {
          count++;
        } else {
          param.values.push({
            codeValue: item.codeValue,
            isActive: item.isActive,
            orderPosition: item.orderPosition,
            codeDescription: item.codeDescription
          });
        }
      });
      if (count > 0) {
        this.$message.error("CodeValue cannot be empty");
        return false;
      }
      apiCreateCodeValue(param).then(res => {
        this.$message.success("success");
        this.table("init");
      });
    }
  }
};
</script>
<style scoped lang="less">
.title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}
.btn {
  margin: 10px 0;
}
</style>
